.galleryCardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
}

.galleryCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px;
}

.thumbnailCardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: auto;
  padding: 24px 0;
}

.thumbnailCard {
  margin: 16px;
}

.crumbContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5%;
}

.oldCrumb {
  cursor: pointer;
  letter-spacing: 2px;
}

.chiefCrumb {
  cursor: pointer;
  font-size: 24px;
  letter-spacing: 2px;
}

.imageContainerXYZ {
  margin: auto;
  width: 150px;
  height: 150px;
  overflow: hidden;
  align-content: center;
  justify-content: center;
  display: flex;
}

.scalableImage {
  height: 100%;
}

.imageTitle {
  margin: 8px 0 24px;
}
