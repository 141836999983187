.authForm {
  display: inline-flex;
  flex-direction: column;
  padding-top: 5%;
  padding-bottom: 2.5%;
}

.errorMessage {
  margin-top: 24px;
  color: #680e11;
}

.loginButton {
  display: flex;
  justify-content: flex-start;
}

.header {
  margin-top: 8px;
  margin-bottom: -56px;
}

.loginCard {
  margin: auto;
  padding: 0 0 24px;
  max-width: 400px;
}

.buttons {
  margin-top: 24px;
}
