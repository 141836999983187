body {
  background-color: #794451;
  color: white;
  font-family: "Avenir Next";
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
}

@font-face {
  font-family: "Avenir Next";
  font-weight: normal;
  src: url(./fonts/Avenir Next Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 200;
  src: url(./fonts/AvenirNext-UltraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir Next";
  font-weight: 700;
  src: url(./fonts/Avenir Next Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir Next";
  font-style: italic;
  font-weight: normal;
  src: url(./fonts/Avenir Next Italic.ttf) format("truetype");
}
