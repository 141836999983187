.error {
  color: #680e11;
}

.helper {
  color: #e9a6b8;
  text-align: center;
}

.imageCard {
  display: flex;
  flex-direction: column;
  flex-grow: false;
  margin: 32px;
}

.imageCardContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.imageCardContent p {
  margin: 0px;
}

.imageCardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  margin: 8px;
}

.totalPrice {
  font-weight: 700;
  text-align: center;
}

.imageCardImageContainer {
  align-content: center;
  display: flex;
  flex-direction: row;
  height: 150px;
  justify-content: center;
}

.imageClass {
  object-fit: contain;
}

.pricing {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
