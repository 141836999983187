.helperText {
  color: #e9a6b8;
  text-align: center;
}

.imageHeader {
  font-size: 24px;
  font-weight: 700;
  margin-top: -16px;
}

.arrowNavIcon {
  cursor: pointer;
}

.arrowNav {
  display: flex;
  flex-direction: column;
  font-size: 12vw;
  justify-content: center;
}

.imageNavigator {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
}

.error {
  color: #680e11;
  text-align: center;
}

.image {
  max-height: 80vh;
  max-width: 70vw;
  object-fit: cover;
  padding: 2.5%;
}
