body {
  text-align: center;
}

.nav {
  border-top: solid #41252c 1px;
  border-bottom: solid #41252c 1px;
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.logo {
  margin: 16px;
  max-width: 75%;
  max-height: 100px;
}

h1 {
  font-family: Avenir Next;
  font-weight: 200;
  letter-spacing: 10px;
}

h2 {
  font-weight: 200;
  letter-spacing: 4px;
}

h3 {
  font-weight: 200;
  letter-spacing: 2px;
}

.navElement {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
}

.arrow {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
}

.bannerContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
  padding: 2.5%;
}

.bannerText {
  font-weight: 700;
}
