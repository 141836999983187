.checkoutReceipt {
  margin: 40px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 12px;
  transition: box-shadow 150ms ease;
}

.checkoutForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.textField {
  margin: 12px;
  min-width: 80px;
  max-width: 50%;
  padding: 12px;
}

.cardError {
  color: #680e11;
  margin: -12px 0 12px;
}

p {
  text-align: left;
}

b {
  font-weight: 700;
}

.checoutForm h3 {
  text-align: left;
}

.StripeElement {
  max-width: 424px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  margin: 20px 0;
  padding: 10px 12px;
}
